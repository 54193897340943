import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { LayoutSplash } from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <LayoutSplash>
    <SEO />
    <Helmet><body className="splash" /></Helmet>
    <h1>
      <span className="name">Robert</span>
      <span className="alt">A</span>bramski
      <span className="title">Designer / Developer</span>
    </h1>
    <nav className="home">
      <ul>
        <li><Link className="btn" to="/work">Work</Link></li>
        <li><Link className="btn" to="/contact">Contact</Link></li>
      </ul>
    </nav>
  </LayoutSplash>
);

export default IndexPage;
